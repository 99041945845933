<template>
  <el-dialog
    class="edit-password-dialog"
    title=""
    :visible.sync="visvible"
    width="490px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="edit-password-dialog-main">
      <div class="phone-content">忘记密码</div>
      <el-form
        :model="form"
        :rules="formRules"
        ref="forgetForms"
        label-width="1px"
        class="forget-form"
      >
        <el-form-item label="" prop="phone" class="password-box form-sms-code">
          <el-input
            type="text"
            v-model="form.phone"
            placeholder="请输入电话号码"
            maxlength="11"
            onInput="value=value.replace(/[^\d]/g,'')"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="smsCode" class="password-box">
          <el-input
            type="text"
            v-model="form.smsCode"
            placeholder="请输入验证码"
          >
          </el-input>
          <el-button
            class="botton-code"
            type="primary"
            :disabled="!canClick"
            @click="countDown"
            >{{ btncontent }}</el-button
          >
        </el-form-item>
        <el-form-item label="" prop="password" class="password-box">
          <el-input
            :type="newPassword ? 'password' : 'text'"
            v-model="form.password"
            placeholder="请输入新密码"
          >
            <i
              v-if="!newPassword"
              class="el-icon-view el-input__icon"
              slot="suffix"
              @click="handleNewIconClick"
            >
            </i>
          </el-input>
          <img
            v-if="newPassword"
            class="password-img"
            :src="require('@/assets/password.png')"
            @click="handleNewIconClick"
          />
        </el-form-item>
        <el-form-item label="" prop="confirmPassword" class="password-box">
          <el-input
            :type="confirmPassword ? 'password' : 'text'"
            v-model="form.confirmPassword"
            placeholder="再次输入新密码"
          >
            <i
              v-if="!confirmPassword"
              class="el-icon-view el-input__icon"
              slot="suffix"
              @click="handleConfirmIconClick"
            >
            </i>
          </el-input>
          <img
            v-if="confirmPassword"
            class="password-img"
            :src="require('@/assets/password.png')"
            @click="handleConfirmIconClick"
          />
        </el-form-item>
      </el-form>

      <div class="footer">
        <div class="footer-btn" @click="onSubmit">确认</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { awaitWrap } from "@/util/patch";
import { smsSendAudit, userRegister } from "@/api/register";
import { forgetPassword } from "@/api/login";

export default {
  props: {
    dialogVisvible: {
      type: Boolean,
      default: false,
    },
    useType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      confirmPassword: true,
      newPassword: true,
      visvible: false,
      checkedAgree: false,
      btncontent: "获取验证码",
      canClick: true,
      totalTime: 60,
      form: {
        phone: "", //手机号码
        password: "", //密码
        smsCode: "", //短信验证码
        confirmPassword: "", //确认密码
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入正确的手机号码",
            trigger: ["blur"],
          },
          {
            validator: this.checkoutPhone,
            trigger: ["change"],
          },
        ],
      },
    };
  },
  created() {
    this.visvible = this.dialogVisvible;
  },
  methods: {
    handleNewIconClick() {
      this.newPassword = !this.newPassword;
    },
    handleConfirmIconClick() {
      this.confirmPassword = !this.confirmPassword;
    },
    //手机号码验证
    checkoutPhone(rules, value, cb) {
      const regPhone = /^1([123456789]\d{0,9})$/;
      if (regPhone.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的手机号码"));
    },
    // 倒计时
    async countDown() {
      if (!this.canClick) return;
      if (!this.form.phone) {
        this.$message({ message: "手机号码不能为空！", type: "error" });
        return;
      }
      const params = {
        phone: this.form.phone,
      };
      const [err, data] = await awaitWrap(smsSendAudit(params));
      if (!err) {
        this.canClick = false;
        this.btncontent = this.totalTime + "秒";
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.btncontent = this.totalTime + "秒";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.btncontent = "重新获取";
            this.totalTime = 60;
            this.canClick = true;
          }
        }, 1000);
      }
    },
    async onSubmit() {
      this.$refs.forgetForms.validate(async (valid) => {
        if (valid) {
          if (this.form.phone.length < 11) {
            this.$message({ message: "请输入正确的手机号码", type: "warning" });
          }
          const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_]{8,}$/;
          if (!reg.test(this.form.password)) {
            this.$message({
              message: "密码至少由8位数字字母组成!",
              type: "warning",
            });
            return;
          }
          if (this.form.password !== this.form.confirmPassword) {
            this.$message({
              message: "确认密码与新密码不一致!",
              type: "warning",
            });
            return;
          }
          this.form.userType = this.useType;
          const [err, data] = await awaitWrap(forgetPassword(this.form));
          if (!err) {
            this.$message({ message: "重置密码成功，请登录！" });
            this.$emit("close");
            if (this.$route.path !== "/home") {
              this.$router.push({
                path: "/",
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-content {
  width: 100%;
  //padding: 0 20px;
  font-weight: 600;
  text-align: left;
  font-size: 30px;
  color: #101d37;
  margin-right: 40px;
}
.form-sms-code {
  position: relative;
}
.edit-password-dialog-main {
  padding: 0 20px;
}
.forget-form {
  padding-top: 38px;
  .botton-code {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 124px;
    height: 40px;
    border: 1px solid #1764ce;
    border-radius: 0 5px 5px 0;
    text-align: center;
    color: #1764ce;
    background: #fff;
  }
}
.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0 10px;
  .footer-btn {
    margin: 0 auto;
    width: 405px;
    background: #1764ce;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    padding: 14px 0;
    cursor: pointer;
  }
}
.password-box {
  position: relative;
}
.password-img {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 14px;
}
</style>
<style  lang="scss">
.edit-password-dialog {
  font-size: 16px;
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 30px;
  }
}
</style>