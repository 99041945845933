import request from '@/util/request';


// 保租房申请列表查询
export function houseApplyList(params) {
  return request.get(`/v3/home/hantang/house/apply`,{params});
}

//申请记录详情查询
export function houseApplyDetails(id) {
  return request.get(`/v3/home/hantang/house/apply/details/${id}`);
}

// 新增保租房申请
export function homeApplyAdd(params) {
  return request.post(`/v3/home/hantang/house/apply`,params);
}


// 修改保租房申请
export function homeApplyUpdate(params) {
  return request.put(`/v3/home/hantang/house/apply`,params);
}


// 查询房间列表
export function homeApplyRoomList(params) {
  return request.get(`/v3/home/hantang/house/apply/room-list`,{params});
}

// 查询未审核或者驳回且有效的申请记录总数是否大于房源列表总数
export function homeApplyCountCheck() {
  return request.get(`/v3/home/hantang/house/apply/count/check`);
}

