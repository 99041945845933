import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user'

Vue.use(Vuex)


export default new Vuex.Store({
  plugins:[
    createPersistedState({
      paths: ['user']
    })
  ],
  state:{
    isShowLoginDialog:false,
    isShowForgetPassDialog:false,
    isShowEditPassDialog: false
  },
  getters: {
  },
  mutations: {    
    setIsShowLoginDialog(state, isShowLoginDialog) {
      state.isShowLoginDialog = isShowLoginDialog;
    },
    setToken(state, token) {
      if(token===''){
        state.user.token = token;      
        state.user.userInfo = null;
      }
    },
    setIsForgetPassDialog(state, isShowForgetPassDialog) {
      state.isShowForgetPassDialog = isShowForgetPassDialog;
    },
    setIsEditPassDialog(state, isShowEditPassDialog) {
      state.isShowEditPassDialog = isShowEditPassDialog;
    }
  },
  actions: {
  },
  modules: {
    user,
  },
})
