<template>
  <div>
    <Nav />
    <RentalListings />
    <NewsAnnouncement />
    <Footer />
  </div>
</template>
<script>
import Nav from "./nav/index";
import RentalListings from "./rental-listings/index";
import NewsAnnouncement from "./news-announcement/index";
import Footer from "@/components/footer";
export default {
  components: {
    Nav,
    RentalListings,
    NewsAnnouncement,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>