
const RE_QUERIES = /\?([^#]*)/;

export function getParamValue(paramName) {
  const queriesStrMatched = RE_QUERIES.exec(window.location.href);
  if (queriesStrMatched) {
    const [, queriesStr] = queriesStrMatched;
    const queries = queriesStr.split('&');
    for (const query of queries) {
      const [key, value] = query.split('=');
      if (key === paramName) {
        return value;
      }
    }
  }
  return '';
}


export const supportMode = getParamValue('support_mode') || !!process.env.SUPPORT_MODE;



export function awaitWrap(promise) {
  return promise.then(data => [null, data]).catch(err => [err, null]);
}


export function mapStateArr(value, object, separator = '') {
  if (Array.isArray(value)) {
    return value.map(key => object[key]).join(separator);
  } else if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  ) {
    return object[value];
  }
}

export function formatMoney(money) {
  if (isNaN(parseFloat(money))) {
    return '';
  }
  money = String(round(money));
  const dotIndex = money.indexOf('.');
  if (dotIndex === -1) {
    return money + '.00';
  } else {
    const validDecimalPartLength = 3;
    let decimalPart = money.substr(dotIndex, validDecimalPartLength);
    for (let i = 0; i < validDecimalPartLength - decimalPart.length; i++) {
      decimalPart += '0';
    }
    return money.substring(0, dotIndex) + decimalPart;
  }
}