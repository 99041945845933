<template>
  <div class="rental-listings">
    <div class="rental-listings-main">
      <div class="rental-listings-main_title">
        RENTAL HOUSING
        <span class="span-pos">出租房源</span>
      </div>
      <div class="rental-listings_tabs">
        <span
          v-for="tab in tabs"
          :key="tab.areaId"
          :class="`rental-listings_tabs-item  ${
            activeTab === tab.areaId ? 'active' : ''
          }`"
          @click="changeTab(tab.areaId)"
          >{{ tab.areaName }}</span
        >
      </div>
      <List v-if="lists.length > 0" :list-data="lists" />
      <el-empty v-else description="暂无数据"></el-empty>
    </div>
  </div>
</template>
<script>
import { homeRoomArea, homeRoomList } from "@/api/home";
import { awaitWrap } from "@/util/patch";
import List from "../list";
export default {
  components: {
    List,
  },
  data() {
    return {
      activeTab: null,
      tabs: [],
      lists: [], //列表数据
    };
  },
  created() {
    this.getHomeRoomArea();
  },
  methods: {
    changeTab(val) {
      this.activeTab = val;
      this.getHomeRoomList();
    },
    async getHomeRoomArea() {
      let [err, data] = await awaitWrap(homeRoomArea());
      if (!err) {
        if (data.list.length > 0) {
          this.tabs = data.list.slice(0, 5);
          this.activeTab = data.list[0].areaId;
          this.getHomeRoomList();
        }
      }
    },
    async getHomeRoomList() {
      let [err, data] = await awaitWrap(
        homeRoomList({
          area: this.activeTab || "",
          pageSize: 6,
          pageNum: 1,
          menuStatus: 2,
        })
      );
      if (!err) {
        this.lists = data.list;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rental-listings {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rental-listings-main {
  width: 1200px;
  padding-top: 60px;
  &_title {
    position: relative;
    font-size: 60px;
    line-height: 85px;
    color: rgba(145, 154, 172, 0.1);
  }
}
.span-pos {
  color: #101d37;
  font-size: 48px;
  line-height: 85px;
  position: absolute;
  top: 0;
  left: 515px;
}

.rental-listings_tabs {
  padding-top: 40px;
  text-align: left;
  font-size: 24px;
  border-bottom: 1px solid #e7e8ed;
  color: #101d37;
}
.rental-listings_tabs-item {
  padding-bottom: 20px;
  margin-right: 46px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  &:last-child {
    padding-right: 0;
  }
}
.active {
  color: #1764ce;
  border-bottom: 4px solid #1764ce;
  font-weight: 600;
}
</style>