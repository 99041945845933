<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    // this.resizeFun();
  },
  methods: {
    resizeFun() {
      //换算方式：1rem=100px
      let docEle = document.documentElement,
        resizeEvt =
          "orientationchange" in window ? "orientationchange" : "resize",
        resizeFun = () => {
          let clientWidth = docEle.clientWidth;
          if (!clientWidth) return;
          // 100是换算系数，相当于1rem=100px
          //1920是作为设计图的基准，我这是按照1920*1080的分辨率，可自行修改
          docEle.style.fontSize = 100 * (clientWidth / 1920) + "px";
        };
      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, resizeFun, false);
      window.addEventListener("DOMContentLoaded", resizeFun, false);
      resizeFun();
    },
  },
};
</script>
<style lang="scss">
html,body {
  margin: 0;
  padding: 0;
}
#app {
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fafafa;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
