
// import { Message } from 'element-ui';

const state = {
  token: '',
  userInfo: null,
};
const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
};

const actions = {
  // async loadUserInfo({ commit }) {
  //   let user = window.parent.parent.__user_data__;
  //   try {
  //     if (!user) {
  //       user = await getUser();
  //     } else {
  //       user = await user;
  //     }
  //     commit('setUserInfo', user.userInfoVO);
  //   } catch (e) {
  //     commit('setUserInfo', {});
  //     Message.error('获取用户信息失败，当前页面数据可能有错误，请关闭标签后重试');
  //     console.error(e);
  //   }
  // }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};