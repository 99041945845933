<template>
  <div class="footer">
    <div class="footer-box">
      <div class="footer-content">
        <div class="footer-content_item">
          <img class="log-img" :src="require('@/assets/header_log.png')" />
        </div>
        <div class="footer-content_item-box">
          <div class="footer-content_item-third">
            <div>主办：哈尔滨汉唐置业有限公司</div>
<!--            <div class="footer-content_item-con">
              <span>承办：</span>
              <span>
                哈尔滨市政府办公厅<br />哈尔滨市工业和信息化局<br />哈尔滨市政府新闻办公室
              </span>
            </div>-->
            <div>网站邮箱：zfw@harbin.gov.cn</div>
          </div>
          <div class="footer-content_item">
            <img class="icon-img" :src="require('@/assets/footer_icon.png')" />
            <img class="web-img" :src="require('@/assets/footer_web.png')" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-buttom">
      <div class="footer-buttom-box">
        <div class="footer-buttom_left">版权所有: 哈尔滨汉唐置业有限公司</div>
        <div class="footer-buttom_right">
          <span @click="icpLink" style="cursor: pointer;">黑ICP备2022003405号-2</span>
          <span @click="icpLink" style="cursor: pointer;">黑ICP证：黑B2-20200276</span>
          <span>公安备案号：23010202010588</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    icpLink() {
      window.open('https://beian.miit.gov.cn','_blank');
      //https://beian.miit.gov.cn
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #303133;
  padding-top: 45px;
  background: #f1f2f3;
}
.footer-box {
  width: 1200px;
  margin: 0 auto;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  &_item-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_item {
    margin-right: 20px;
    background: #eaebeb;
    width: 260px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 0;
    &:first-child {
      background: none;
      justify-content: left;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &_item-third {
    text-align: left;
    margin-right: 20px;
    background: #eaebeb;
    line-height: 20px;
    width: 200px;
    height: 116px;
    padding: 18px 40px 10px 20px;
  }
  &_item-con {
    display: flex;
    padding-bottom: 13px;
    & > span:first-child {
      width: 43px;
      display: block;
    }
  }
}
.flex_wrap {
  flex-wrap: wrap;
  justify-content: left;
  &_item {
    width: calc(50% - 40px);
    text-align: left;
    padding-left: 40px;
  }
}
.log-img {
  width: 126px;
  height: 105px;
}
.icon-img {
  width: 56px;
  height: 68px;
}
.web-img {
  width: 110px;
  height: 55px;
  margin-left: 26px;
}
.footer-buttom {
  background: #eaebeb;
  padding: 13px 0;
  .footer-buttom-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_left {
    flex: 1;
    text-align: left;
  }
  &_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 575px;
  }
}
</style>