<template>
  <div class="nav-box">
    <div class="nav-box_content-box">
      <div class="nav-box_content">
        <div v-if="!token" class="nav-box_top">
          <span
            class="welcome"
            @click="(isShowMenu = false), setIsShowLoginDialog(true)"
            >你好，请登录</span
          >

          <span class="line-icon">|</span>
          <span class="logon" @click="goLoagin">注册</span>
        </div>
        <div v-if="token" class="nav-box_top">
          <span @click="isShowMenu = !isShowMenu" class="name-img-box" style="">
            <img
              width="32px"
              height="32px"
              :src="
                userInfo.headerPic
                  ? userInfo.headerPic
                  : require('@/assets/info_img.png')
              "
              alt=""
              style="margin-right: 10px; border-radius: 50%"
            />
            {{ userInfo.name }}
            <i class="el-icon-arrow-down" />
          </span>
          <ul v-if="isShowMenu" class="muenu-mini">
            <li class="name-img-box">
              <img
                width="40px"
                height="40px"
                :src="
                  userInfo.headerPic
                    ? userInfo.headerPic
                    : require('@/assets/info_img.png')
                "
                alt=""
                style="border-radius: 50%"
              />
              {{ userInfo.name }}
            </li>
            <li>
              <div @click="personalInfo">
                <i class="el-icon-user bolid-icon" />
                <span class="li-padding">个人中心</span>
              </div>
              <div
                class="li-content-div"
                @click="(isShowMenu = false), setIsEditPassDialog(true)"
              >
                <i class="el-icon-document-remove bolid-icon" />
                <span class="li-padding">修改密码</span>
              </div>
            </li>
            <li @click="jumpApplication">
              <i class="el-icon-folder-checked bolid-icon" />
              <span class="li-padding">我的申请</span>
            </li>
            <li @click="loginOutFunc">
              <i class="el-icon-switch-button bolid-icon" />
              <span class="li-padding">退出登录</span>
            </li>
          </ul>
        </div>
        <div class="img-box">
          <img class="nav-img" :src="require('@/assets/nav_log.png')" />
        </div>
        <div class="nav-box_tabs">
          <span
            v-for="tab in tabs"
            :key="tab.idx"
            :class="`nav-box_tabs-item  ${
              activeTab === tab.idx ? 'active' : ''
            }`"
            @click="changeTab(tab.idx)"
            >{{ tab.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="nav-box_menu-box">
      <div class="nav-box_menu-box-main">
        <div
          v-for="menu in menus"
          :key="menu.idx"
          :class="` ${menu.icon} nav-box_menu-box-item`"
          @click="changeMenuTab(menu.link, menu.idx)"
        >
          <div class="nav-box_menu-box-item_title">
            {{ menu.name }} <i class="el-icon-arrow-right icon-right" />
          </div>
          <p class="nav-box_menu-box-item_tip">{{ menu.tip }}</p>
        </div>
      </div>
    </div>
    <Login
      v-if="isShowLoginDialog"
      :dialogVisvible="isShowLoginDialog"
      @close="() => setIsShowLoginDialog(false)"
      @forget="forgetPassDialog"
    />
    <ForgetPassword
      v-if="isShowForgetPassDialog"
      :dialogVisvible="isShowForgetPassDialog"
      :useType="useType"
      @close="() => setIsForgetPassDialog(false)"
    />
    <EditPassword
      v-if="isShowEditPassDialog"
      :dialogVisvible="isShowEditPassDialog"
      @close="() => setIsEditPassDialog(false)"
    />
  </div>
</template>
<script>
import { homeUserOutLogin, getUserDetail } from "@/api/login";
import { awaitWrap } from "@/util/patch";
import { mapMutations, mapState } from "vuex";
import Login from "@/views/login/index.vue";
import ForgetPassword from "@/views/login/forgetPassword.vue";
import EditPassword from "@/views/login/editPassword.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapUserState } = createNamespacedHelpers("user");
export default {
  components: {
    Login,
    ForgetPassword,
    EditPassword,
  },
  data() {
    return {
      isShowMenu: false,
      activeTab: "home",
      useType: "",
      tabs: [
        { name: "首页", idx: "home" },
        { name: "新闻公告", idx: "news" },
        { name: "出租房源", idx: "rental-listings" },
      ],
      menus: [
        {
          // 跳出租房源列表
          name: "保租房申请",
          idx: 1,
          tip: "提交保租房申请",
          icon: "bz_sq",
          link: "rental-listings",
        },
        {
          name: "项目申请",
          idx: 2,
          tip: "提交项目申请",
          icon: "xm_sq",
          link: "add-project-info",
        },
        {
          // 跳保租房申请列表
          name: "保租房进度",
          idx: 3,
          tip: "查看保租房审核进度",
          icon: "bz_jd",
          link: "individual-application",
        },
        {
          name: "项目进度",
          idx: 4,
          tip: "查看项目审核进度",
          icon: "xm_jd",
          link: "project-application",
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "isShowLoginDialog",
      "isShowForgetPassDialog",
      "isShowEditPassDialog",
    ]),
    ...mapUserState(["userInfo", "token"]),
  },
  created() {
    if (this.token) {
      this.getUserDetailsInfo(); //判断token是否还在登录状态
    }
  },
  methods: {
    ...mapMutations([
      "setIsShowLoginDialog",
      "setToken",
      "setIsForgetPassDialog",
      "setIsEditPassDialog",
    ]),

    async getUserDetailsInfo() {
      let [err, data] = await awaitWrap(getUserDetail());
      // if (err && (this.$route.path !== "/home" || this.$route.path !== "/")) {
      //   this.$router.push({
      //     path: "/home",
      //   });
      // }
    },
    goLoagin() {
      this.$router.push({
        path: "/register",
      });
    },
    changeTab(val) {
      this.activeTab = val;
      this.$router.push({
        path: "/" + val,
      });
    },

    changeMenuTab(val, idx) {
      console.log(val, idx);
      if (!this.token) {
        this.$message({
          message: "请先登录！",
          type: "warning",
        });
        return;
      }
      if (val === "home") {
        return;
      }
      if (Number(this.userInfo.userType) === 2 && (idx === 1 || idx === 3)) {
        this.$message({
          message: "保租申请针对个人保租房申请开放，企业项目请前往项目进度看！",
          type: "warning",
        });
        return;
      }

      if (Number(this.userInfo.userType) === 1 && (idx === 2 || idx === 4)) {
        this.$message({
          message: "项目申请针对企业项目开放，个人请前往保租房进度看申请！",
          type: "warning",
        });
        return;
      }
      this.$router.push({
        path: "/" + val,
      });
    },
    forgetPassDialog(e) {
      this.useType = e;
      this.setIsForgetPassDialog(true);
      this.$nextTick(() => {
        this.setIsShowLoginDialog(false);
      });
    },
    //退出登录
    loginOutFunc() {
      this.isShowMenu = false;
      this.$alert("是否确定退出系统？", "系统提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action === "confirm") {
            let [err, data] = await awaitWrap(homeUserOutLogin());
            if (!err) {
              this.setToken("");
              this.$router.push({
                path: "/home",
              });
            }
          }
        },
      });
    },
    personalInfo() {
      this.isShowMenu = false;
      this.$router.push({
        path: "/personal-center",
      });
    },

    jumpApplication() {
      let path =
        this.userInfo.userType === 1
          ? "/individual-application"
          : "/project-application";
      if (this.$route.path === path) {
        this.isShowMenu = false;
        return;
      }
      this.$router.push({
        path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-box {
  position: relative;
  color: #fff;
  width: 100%;
  height: 560px;
  background-image: url("@/assets/nav.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #fff;

  .line-icon {
    color: #eee;
    opacity: 0.5;
  }

  &_content-box {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  &_content {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
  }
  &_top {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 14px;
    position: relative;
    cursor: pointer;

    .name-img-box {
      display: flex;
      align-items: center;
      cursor: default;

      & > img {
        margin-right: 20px;
      }
    }
    .muenu-mini {
      position: absolute;
      top: 45px;
      right: -30px;
      list-style: none;
      background-color: #fff;
      color: #000;
      border-radius: 4px;
      border: 1px solid #eceef5;
      padding: 0;
      z-index: 8;
      & > li {
        padding: 20px 30px 20px 30px;
        width: 170px;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #eceef5;
        &:first-child {
          border-bottom: none;
          padding: 20px 0 15px 30px;
          font-weight: 400;
        }

        &:last-child {
          padding-bottom: 30px;
          border-bottom: none;
        }
        .li-content-div {
          padding-top: 20px;
        }

        .bolid-icon {
          font-weight: bold;
        }
        .li-padding {
          padding-left: 20px;
        }
      }
    }
  }
  .welcome {
    height: 17px;
    line-height: 17px;
    padding-right: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
  .logon {
    height: 17px;
    line-height: 17px;
    padding-left: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
  .img-box {
    width: 270px;
    height: 40px;
    position: relative;
    & > img {
      height: 105px;
      width: 115px;
      position: absolute;
      top: -22px;
    }
  }

  &_tabs {
    text-align: right;
    font-size: 22px;
  }
  &_tabs-item {
    padding-bottom: 10px;
    margin-left: 115px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }
  }
  .active {
    border-bottom: 4px solid #fff;
  }
  &_menu-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &_menu-box-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_menu-box-item {
    width: 300px;
    height: 165px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #fff;
    cursor: pointer;
    &_title {
      font-size: 24px;
      padding: 30px 24px 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_tip {
      text-align: left;
      padding-left: 30px;
    }
  }
  .bz_jd {
    background-image: url("@/assets/bz_jd.png");
  }
  .bz_sq {
    background-image: url("@/assets/bz_sq.png");
  }
  .xm_jd {
    background-image: url("@/assets/xm_jd.png");
  }
  .xm_sq {
    background-image: url("@/assets/xm_sq.png");
  }
}
</style>

